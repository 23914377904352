<template lang="pug">
    div(class="sz-InitiateCaptureDashboard")
        span(class="sz-InitiateCaptureDashboard-title") {{ $t('initiateCapture.title') }}
        div(
            v-if="!setIsValid",
            class="sz-InitiateCaptureDashboard")
            div(class="sz-InitiateCaptureDashboard-breadcrumb") {{ $t('initiateCapture.selectedWorker') }} {{ fullName }}
            div(class="sz-InitiateCaptureDashboard-checklist-title") {{ $t(`initiateCapture.reminder`) }}
            div(class="sz-InitiateCaptureDashboard-checklist-items") {{ $t(`initiateCapture.check1`) }}
            div(class="sz-InitiateCaptureDashboard-checklist-items") {{ $t(`initiateCapture.check2`) }}
            div(class="sz-InitiateCaptureDashboard-checklist-items-last") {{ $t(`initiateCapture.check3`) }}
            div(class="sz-InitiateCaptureDashboard-checklist-items-last") {{ $t(`initiateCapture.ledNote`) }}
            div(class="sz-InitiateCaptureDashboard-images")
                div(class="sz-InitiateCaptureDashboard-images-padded")
                    img(
                        v-bind:src="frontImg",
                        class="sz-LifeBooster-Logo-image")
                div(class="sz-InitiateCaptureDashboard-images-padded")
                    img(
                        v-bind:src="backImg",
                        class="sz-LifeBooster-Logo-image")
            div(class="sz-InitiateCaptureDashboard-form")
                div(class="sz-InitiateCaptureDashboard-form-input")
                    FormSectionInput(
                        :title="$t(`modals.modalInitiateCapture.enterSetID`)",
                        :placeholder="$t(`modals.modalInitiateCapture.exampleSetID`)",
                        :maxLength="maxLength",
                        @updated="updateSetID",
                        @enter="validateSet")
                div(
                    v-if="!setIsValid",
                    @click="validateSet",
                    class="sz-InitiateCaptureDashboard-footer-submit") {{ $t(`modals.modalInitiateCapture.next`)}}
        div(
            v-if="setIsValid && !captureStarted && !disableSubmit",
            class="sz-InitiateCaptureDashboard")
                span(class="sz-InitiateCaptureDashboard-checklist-title") {{ $t('modals.modalInitiateCapture.selectedSet') }} {{ setID }}
                div(class="sz-InitiateCaptureDashboard-img")
                    PoseInitiateCapture
                div(class="sz-InitiateCaptureDashboard-checklist-title") {{ $t(`initiateCapture.startReminder`) }}
                div(class="sz-InitiateCaptureDashboard-footer")
                    div(
                        @click="reselectSet",
                        class="sz-InitiateCaptureDashboard-footer-button \
                            sz-InitiateCaptureDashboard-footer-cancel") {{ $t(`modals.modalInitiateCapture.back`)}}
                    div(@click="startCapture",
                        class="sz-InitiateCaptureDashboard-footer-submit") {{ $t(`modals.modalInitiateCapture.start`)}}
        div(v-if="disableSubmit && !captureStarted",
            class="sz-InitiateCaptureDashboard")
            LoadingSpinner(color="light")
        div(
            v-if="captureStarted",
            class="sz-InitiateCaptureDashboard")
                div(class="sz-InitiateCaptureDashboard-img")
                    PoseInitiateCapture
                div(class="sz-InitiateCaptureDashboard-countdown") {{ $t('modals.modalInitiateCapture.holdPose') }}
                div(class="sz-InitiateCaptureDashboard-countdown-timer") {{ timerCount }}
                div(class="sz-InitiateCaptureDashboard-countdown") {{ $t('modals.modalInitiateCapture.seconds') }}
</template>

<script>
    import FormSectionInput from 'components/Shared/FormSectionInput'
    import constants from 'helpers/constants'
    import modalSettings from 'mixins/modalSettings'
    import LoadingSpinner from "components/Shared/LoadingSpinner"
    import formValidator from 'mixins/formValidator'
    import PoseInitiateCapture from '../Modal/ModalInitiateCapture/PoseInitiateCapture'
    import EventBus from 'src/eventBus'

    import { mapGetters } from 'vuex'

    export default {
        name: "InitiateCaptureDashboard",

        components: {
            LoadingSpinner,
            FormSectionInput,
            PoseInitiateCapture,
        },

        mixins: [
            modalSettings,
            formValidator,
        ],

        data () {
            return {
                setID: null,
                setIsValid: false,
                timerCount: 0,
                captureStarted: false,
                frontImg: require("../../assets/images/FrontSensorPos.png"),
                backImg: require("../../assets/images/BackSensorPos.png"),
                disableSubmit: false,
            }
        },

        computed: {
            ...mapGetters([
                'validatingSid',
                'userID',
                'firstName',
                'lastName',
            ]),

            maxLength() {
                return constants.INITIATE_CAPTURE.SID_MAX_LENGTH
            },

            poseTimer() {
                return constants.INITIATE_CAPTURE.TPOSE_TIMER
            },

            sidTitle () {
                return this.$t(`initiateCapture.sid`)
            },

            invalidMessage () {
                return this.validationStatus
            },

            fullSetID() {
                return `0x` + this.setID
            },

            fullName() {
                return `${this.firstName} ${this.lastName}`
            },
        },

        watch: {
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--
                        }, 1000)
                    }
                },
                immediate: true,
            },
        },

        mounted() {
              EventBus.$on('USER_CONFIRMED', () => {
                  this.setIsValid = true
              })
        },

        methods: {
            updateSetID(setID) {
                this.setIsValid = false
                this.setID = setID
            },

            reselectSet() {
                this.setIsValid = false
                this.setID = ''
            },

            setTimer() {
                this.timerCount = constants.INITIATE_CAPTURE.POSE_TIMER
            },

            async startCapture() {
                if (!this.disableSubmit) {
                    try {
                        // Adding this to prevent workers from clicking on the submit multiple times
                        // while the API call to start an assessment is going through
                        this.disableSubmit = true
                        await this.$store.dispatch('startCapture', {
                          sid: this.setID,
                          userID: this.userID,
                        })
                        this.captureStarted = true

                        this.setTimer()
                        setTimeout(async() =>
                            await this.$store.dispatch('showModal', this.constructModalSettings(
                                constants.MODAL_TYPES_COMPONENT_NAMES.MODAL_SUCCESS,
                                false,
                                {
                                    message: this.$t(`initiateCapture.successMessage`, {name: this.fullName, set: this.setID, date: new Date().toLocaleTimeString()}) +
                                        this.$t(`initiateCapture.confirmationMessage`),
                                    dismissible: false,
                                    closeButton: true,
                                    duration: constants.INITIATE_CAPTURE.SIGN_OUT_TIMEOUT,
                                    performAction: true,
                                    action: () => this.$auth.logout({
                                        returnTo: window.location.origin,
                                    }),
                                },
                                false,
                                true)
                            ),
                            this.timerCount * 1000
                        )
                    } catch(error) {
                        this.failModal(
                            this.$t("pleaseTryAgain"),
                            this.$t("somethingWentWrong"),
                            () => this.showConfirmModal = true
                        )
                        this.disableSubmit = false
                    }
                }
            },

            async validateSet() {
                if (this.setID.length === 6) {
                    try {
                        this.setIsValid = false
                        await this.$store.dispatch(
                            'getDeviceSet',
                            this.fullSetID,
                        )

                        let isSetActive = await this.isSetActive()

                        if (isSetActive) {
                            await this.confirmModal(
                                this.$t("modals.modalInitiateCapture.setNotice"),
                                this.$t("modals.modalInitiateCapture.setInUse", { set: this.setID }))
                        } else {
                            this.setIsValid = true
                        }
                    } catch(error) {
                        await this.failModal(
                              this.$t("modals.modalInitiateCapture.enterValidSet"),
                              this.$t("modals.modalInitiateCapture.invalidSet"),
                              () => this.showConfirmation = true)
                    }
                } else {
                  await this.failModal(
                        this.$t("modals.modalInitiateCapture.enterValidSet"),
                        this.$t("modals.modalInitiateCapture.invalidSet"),
                        () => this.showConfirmation = true)
                }
            },

            async isSetActive() {
                try {
                    let capture = await this.$store.dispatch(
                        'getActiveCapture',
                        this.fullSetID,
                    )
                    return capture ? true : false
                } catch (err) {
                    // If this API call fails, we cannot confirm or deny if the set is currently in use
                    // So we return true here to trigger a warning that the set may be in use
                    return true
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-InitiateCaptureDashboard {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            padding-top: 0.5rem;
            padding-bottom: 1.5rem;
            font-size: 15px;
            @extend %font-topbar-heading;
            color: $color-white;
        }

        &-breadcrumb {
            padding-bottom: 2rem;
        }

        &-checklist {
          color: $color-white;
          text-align: left;

          &-title {
              padding-bottom: 1rem;
              white-space: pre;

              &-info {
                  font-weight: 600;
                  text-decoration: underline;
                  padding-top: 1rem;
                  padding-bottom: 0.5rem;
              }
          }

          &-items {
              padding-bottom: 0.5rem;

              &-last {
                  padding-bottom: 2rem;
              }
          }
        }

        &-images {
            display: flex;
            flex-direction: row;
            padding-bottom: 2rem;

            &-padded {
              padding: 0 0.5rem;
            }
        }

        &-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 18rem;

            &-input {
                width: 100%;
                padding-bottom: 1rem;
            }
        }

        &-countdown {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $color-white;
            padding-bottom: 0.25rem;

            &-timer {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $color-white;
              @extend %font-topbar-heading;
              font-size: 25px;
              padding-bottom: 0.25rem;
            }
        }

        &-img {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 1rem;
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: 0.5rem;
            padding-bottom: 1rem;

            &-button {
                @extend %font-topbar-heading;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
            }

            &-reminder {
                padding-bottom: 1rem;
                white-space: pre;
            }

            &-submit {
                @extend %font-topbar-heading;
                @extend %button-layout;
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1rem;
                border-radius: 2;
                color: $color-white;
                margin: 0 1rem;
                cursor: pointer;
            }

            &-cancel {
                font-size: 13px;

                &-single {
                    font-size: 13px;
                    padding-top: 0.5rem;
                }
            }
        }
    }

    .empty {
        color: lighten($color-table-font-black, 30%);
        background-color: lighten($color-box-silver, 30%);
        cursor: default;
    }

    .loading {
        position: absolute;
        @extend %button-layout;
        width: 6.22rem;
        height: 0.925rem;
        background-color: lighten($color-box-silver, 30%);
        opacity: .5;
    }

</style>
