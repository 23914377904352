<template lang="pug">
    Layout(:showWorkerCard="showWorkerCard")
        InitiateCaptureDashboard
</template>

<script>
    import InitiateCaptureDashboard from 'components/Dashboards/InitiateCaptureDashboard'
    import Layout from 'router/layouts/main'

    export default {
        name: "InitiateCapture",

        components: {
            Layout,
            InitiateCaptureDashboard,
        },

        computed: {
            showWorkerCard () {
                return false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>